import { render, staticRenderFns } from "./MatchStatics.vue?vue&type=template&id=0ef994f0&scoped=true"
import script from "./MatchStatics.vue?vue&type=script&lang=js"
export * from "./MatchStatics.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ef994f0",
  null
  
)

export default component.exports