var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-100"},[_c('ul',{staticClass:"tabs flex align-center justify-center p-t-10 p-b-10"},_vm._l((_vm.ranks),function(tab){return _c('li',{key:tab.id,staticClass:"tab text-center font-12 pointer font-500",class:{
            'is-active':tab.id === _vm.currentType
        },on:{"click":function($event){return _vm.changeTab(tab)}}},[_c('span',[_vm._v(_vm._s(tab.label))])])}),0),_c('div',{staticClass:"w-100 p-relative vip-box"},[_c('div',{staticClass:"tops p-t-15 p-relative"},_vm._l((3),function(i){return _c('div',{key:i,staticClass:"top p-absolute flex flex-column align-center",class:`top${i}`},[_c('IconPng',{attrs:{"icon":`gift/top${i}`,"width":i === 1 ? 90 : 75,"height":i === 1 ? 90 : 75}}),_c('span',{staticClass:"avatar overflow-hidden d-inline-block p-absolute"},[(_vm.tops[i-1] )?_c('span',{staticClass:"w-100 h-100 d-inline-block bg-center bg-no-repeat bg-size-cover",style:({
                        backgroundImage: `url(${_vm.tops[i-1].avatar})`
                      })}):_vm._e()]),_c('span',{staticClass:"count p-absolute"},[_c('span',{staticClass:"scale-7 text-center value text-white"},[_vm._v(_vm._s(_vm.tops[i-1] ? _vm.tops[i-1].total : ''))])]),_c('span',{staticClass:"font-12 text-center d-inline-block text-ellipsis font-500",class:{
                    'nickname': _vm.tops[i-1]
                }},[_c('custom-span',{staticClass:"w-100",attrs:{"content":_vm.tops[i-1] ? _vm.tops[i-1].nickname : '虚位以待'}})],1),(_vm.tops[i-1] && _vm.tops[i-1].label)?_c('span',{staticClass:"label d-inline-block",class:`label${[_vm.tops[i-1].label]}`}):_vm._e()],1)}),0),_c('div',{staticClass:"left-vips w-100 p-absolute transition-3"},_vm._l((7),function(i){return _c('div',{key:i + 3,staticClass:"row p-l-15 m-b-15 p-r-20 flex align-center justify-between"},[_c('span',{staticClass:"rank d-inline-block font-12 text-999"},[_vm._v(_vm._s(i + 3))]),_c('div',{staticClass:"flex flex-1 m-l-10 align-center justify-between"},[_c('div',{staticClass:"flex align-center"},[_c('div',{staticClass:"label m-r-5",class:`label${_vm.tops[i+2] ? _vm.tops[i+2].label : '0'}`}),_c('div',{staticClass:"name font-12 font-400 text-333"},[(_vm.tops[i+2] && _vm.tops[i+2].label)?_c('span',{staticClass:"label",class:`vip-label vip-label-${_vm.tops[i+2].label}`}):_vm._e(),_c('span',{staticClass:"nickname"},[_vm._v(_vm._s(_vm.tops[i+2] ? _vm.tops[i+2].nickname : '虚位以待'))])])]),_c('div',{staticClass:"level"},[(_vm.tops[i+2])?[_c('div',{staticClass:"font-12 font-400 level-text",class:{
                            'up': _vm.tops[i+2].up_down === 1,
                            'down': _vm.tops[i+2].up_down === 2
                        }},[_c('span',{staticClass:"label"},[_vm._v(_vm._s(_vm.tops[i+2].total))]),(_vm.tops[i+2].up_down === 1)?_c('i',{staticClass:"el-icon-arrow-up font-12"}):_c('i',{staticClass:"el-icon-arrow-down font-12"})])]:_c('span',{staticClass:"change"},[_vm._v("-")])],2)])])}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }