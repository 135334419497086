var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.isLoading),expression:"isLoading"}],staticClass:"loading-height w-100 competition-box",attrs:{"element-loading-background":"transparent"}},[(_vm.list.length)?_vm._l((_vm.list),function(item,index){return _c('div',{key:item.matchId,staticClass:"row flex align-center",class:{
                'is-stripped': index % 2 === 0
            }},[_c('div',{staticClass:"left flex justify-between align-center"},[_c('div',{staticClass:"time flex font-14 font-400 justify-center align-center"},[_c('span',{staticClass:"text-center date"},[_vm._v(_vm._s(_vm._f("getDate")(item.matchTime)))]),_c('span',{staticClass:"text-center"},[_vm._v(_vm._s(_vm._f("getTime")(item.matchTime)))])]),(item.roundCn)?_c('span',{staticClass:"font-14 league text-6f font-400"},[_vm._v(_vm._s(item.leagueChsShort + item.roundCn))]):_c('span',{staticClass:"font-14 league text-center text-6f font-400"},[_vm._v("--")])]),_c('div',{staticClass:"middle flex align-center p-l-25 font-14 text-333"},[_c('div',{staticClass:"team1 flex align-center"},[_c('span',{staticClass:"team-name text-center"},[_c('CustomSpan',{attrs:{"content":item.homeChs}})],1),_c('span',{staticClass:"icon m-l-15 m-r-20 bg-center bg-no-repeat bg-size-cover border-radius-50 d-inline-block",style:({
                        backgroundImage: `url(${item.homeLogo})`
                    })}),_c('span',{staticClass:"score font-22"},[_vm._v(_vm._s(item.homeScore || 0))])]),_c('span',{staticClass:"m-l-10 m-r-10 font-22"},[_vm._v("-")]),_c('div',{staticClass:"team1 flex align-center"},[_c('span',{staticClass:"score font-22"},[_vm._v(_vm._s(item.awayScore || 0))]),_c('span',{staticClass:"icon m-l-15 m-r-20 bg-center bg-no-repeat bg-size-cover border-radius-50 d-inline-block",style:({
                        backgroundImage: `url(${item.awayLogo})`
                    })}),_c('span',{staticClass:"team-name text-center"},[_c('CustomSpan',{attrs:{"content":item.awayChs}})],1)])]),_c('div',{staticClass:"right text-center"},[_c('span',{staticClass:"d-inline-block pointer font-12 text-center subscribe",class:{
                        'is-subscribed': item.isAppointment === 1
                    },on:{"click":function($event){return _vm.cancelSubscribe(item)}}},[_vm._v(_vm._s(_vm._f("getSubscribeInfo")(item)))])])])}):_vm._e(),(_vm.list && !_vm.list.length && !_vm.isLoading)?_c('no-data'):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }